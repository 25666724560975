<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear
        v-if="b_Loading"
        class="my-1"
        indeterminate
        color="primary"
      />
      <base-card>
        <v-card-title>{{ $t('wanted_workload') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="">
              <v-range-slider
                v-model="rangeWorkload"
                :max="max"
                :min="min"
                hide-details
                class="align-center"
                thumb-label="always"
                :thumb-size="24"
                @change="setEditMode()"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="rangeWorkload[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeWorkload, 0, $event)"
                  />
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="rangeWorkload[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(rangeWorkload, 1, $event)"
                  />
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <div
            v-if="editMode"
            class="d-flex flex-wrap mt-4"
          >
            <v-btn
              class="ma-2"
              outlined
              color="green"
              small
              @click="setWantedWorkload()"
            >
              {{ $t('general.save') }}
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              small
              @click="cancelEditMode()"
            >
              {{ $t('general.cancel') }}
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12">
      <base-card>
        <v-card-title>{{ $t('effective_workload') }}: {{ getTotalWorkload }} {{ $t('lessons') }}</v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="workload"
            item-key="title"
            class="elevation-1 table-one"
            multi-sort
            hide-default-footer
          >
            <template v-slot:item.title="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <v-icon
                    :class="[item.iconClass]"
                    v-text="item.icon"
                  />
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.title }}
                </p>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Pensum',
    },
    data () {
      return {
        min: config.generalValues.minWorkload,
        max: config.generalValues.maxWorkload,
        rangeWorkload: [0, 0],
        currentRangeWorkload: [0, 0],
        editMode: false,
        workload: [],
        search: '',
        selected: [],
        b_Loading: true,
        headers: [
          {
            text: this.$t('class'),
            align: 'start',
            sortable: false,
            value: 'course',
          },
          { text: this.$t('formation'), value: 'formation' },
          { text: this.$t('lessons'), value: 'workload' },
        ],
        i_TacherAddressRoleID: null,
      }
    },
    watch: {
      i_PlanningPeriodID () {
        this.b_Loading = true
        this.getWantedWorkload()
        this.getEffectiveWorkload()
      }
    },
    computed: {
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      getTotalWorkload () {
        let Li_TotalWorkload = 0
        this.workload.forEach(workload_item => {
          Li_TotalWorkload += workload_item.workload
        })
        return Li_TotalWorkload
      },
      ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
    },
    methods: {
      setWantedWorkload() {
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/wanted-workload`, {
          i_MinWorkload: this.rangeWorkload[0],
          i_MaxWorkload: this.rangeWorkload[1],
        })
          .then(response => {
            this.editMode=false; 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.currentRangeWorkload = this.rangeWorkload;
            this.requestSent = true
            this.responseMessage = 'Pensum gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getWantedWorkload () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/wanted-workload`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.rangeWorkload=[response.i_MinWorkload, response.i_MaxWorkload];
            this.currentRangeWorkload = this.rangeWorkload;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getEffectiveWorkload () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/effective-workload`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.workload = response
            this.b_Loading = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setEditMode () {
        this.editMode = true
      },
      cancelEditMode () {
        this.editMode = false
        this.rangeWorkload = this.currentRangeWorkload
      }
    },
    beforeMount () {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      this.getWantedWorkload()
      this.getEffectiveWorkload()
    },
  }
</script>
